import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import NavHeader from '../components/products/NavHeader'
import ChViewer from '../components/products/charas/ChViewer'

const Charas = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Page>
      <Container>
        <NavHeader productName={'charas'}/>
        <ChViewer productName={'CHARAS'} />
      </Container>
    </Page>
  </IndexLayout>
)

export default Charas
